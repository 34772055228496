import React, { PureComponent, useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet"
import Page from "../components/project_d.js"
import IndexSPA from "../components/IndexSPA"
import { Children } from "react";
import window from 'global'

// export default Page

const Index = (props) => {
  return (
    <>
      <Helmet
      title="社宅居民訪談 / HOME TO ALL 我們也可以這樣生活 / 台北社會住宅藝術計畫"
      meta={[
        {
          name: `description`,
          content: "社宅居民訪談",
        },
        {
          property: `og:title`,
          content: "社宅居民訪談 / HOME TO ALL 我們也可以這樣生活 / 台北社會住宅藝術計畫",
        },
        // {
        //   property: `og:url`,
        //   content: 'https://online.home-to-all.com' + window.location.pathname,
        // },
        {
          property: `og:description`,
          content: "社宅居民訪談",
        },
        {
          property: `og:image`,
          content: 'https://online.home-to-all.com' + require('../images/422socialimage.jpg')
        }
      ]}
      >
      </Helmet>
      <IndexSPA />
      {/* <Page /> */}
    </>
  )
 };
 
 // Index.propTypes = {}
 
 export default Index;

 